import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const wrapperStyles = theme => ({
  marginTop: theme.space[15],
})

const rowStyles = theme => ({
  display: `flex`,
  flexDirection: `column`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const imageStyles = theme => ({
  marginLeft: 0,
  marginRight: 0,
  borderRadius: theme.radii[3],
  marginBottom: theme.space[8],

  [theme.mediaQueries.phablet]: {
    marginRight: theme.space[8],
  },
})

const doggoStyles = theme => [
  imageStyles(theme),
  {
    [theme.mediaQueries.phablet]: {
      marginLeft: `calc(480px + ${theme.space[8]})`,
    },
  },
]

const rowOffsetStyles = theme => ({
  [theme.mediaQueries.phablet]: {
    transform: `translateX(-16px)`,
  },
})

const imageOffsetStyles = theme => ({
  [theme.mediaQueries.phablet]: {
    transform: `translateX(-100px)`,
  },
})

const PhotoGrid = () => {
  return (
    <div css={wrapperStyles}>
      <div css={rowStyles}>
        <StaticImage
          css={imageStyles}
          src="../../assets/careers/staff-photo-1.jpg"
          layout="constrained"
          height={240}
          alt="Two Gatsby employees manning a conference table"
        />
        <StaticImage
          css={imageStyles}
          src="../../assets/careers/staff-photo-2.jpg"
          layout="constrained"
          height={240}
          alt="gatsby employee singing karaoke very poorly"
        />
      </div>
      <div css={theme => [rowStyles(theme), rowOffsetStyles(theme)]}>
        <StaticImage
          css={theme => [imageStyles(theme), imageOffsetStyles(theme)]}
          src="../../assets/careers/staff-photo-3.jpg"
          layout="constrained"
          height={240}
          alt="enthusiastic yet confused cofounde"
        />
        <StaticImage
          css={imageStyles}
          src="../../assets/careers/staff-photo-4.jpg"
          layout="constrained"
          height={240}
          alt="satisfied looking product designer wearing a delightful red jacket"
        />
        <StaticImage
          css={imageStyles}
          src="../../assets/careers/staff-photo-5.jpg"
          layout="constrained"
          height={240}
          alt="developer doing a great job explaining a highly complex problem"
        />
      </div>
      <div css={rowStyles}>
        <StaticImage
          css={doggoStyles}
          src="../../assets/careers/pupper-photo.jpg"
          layout="constrained"
          height={240}
          alt="cute pupper wearing crocodile hoodie"
        />
      </div>
    </div>
  )
}

export default PhotoGrid

import React from "react"
import { keyframes } from "@emotion/core"
import { getTheme } from "gatsby-interface"

const rotate = keyframes({
  "0%": { transform: `rotate(7deg)` },
  "100%": { transform: `rotate(367deg)` },
})

const { colors } = getTheme()

const RotatingSquare = ({
  size = 200,
  color = colors.yellow[50],
  ...delegated
}) => {
  return (
    <div css={{ zIndex: -1 }} {...delegated}>
      <svg
        aria-hidden="true"
        viewBox="0 0 200 200"
        style={{
          width: size,
          height: size,
        }}
        css={{
          "@media (prefers-reduced-motion: no-preference)": {
            animation: `${rotate} 360s linear infinite`,
          },
        }}
      >
        <rect x={0} y={0} width={200} height={200} fill={color} />
      </svg>
    </div>
  )
}

export default RotatingSquare

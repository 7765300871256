import React from "react"
import { MdSubdirectoryArrowLeft as DownRightArrow } from "react-icons/md"

import getAnimationsEnabled from "../../utils/enable-animations"

const linkStyles = theme => ({
  color: theme.colors.lilac,
  textDecoration: "none",

  ":hover": {
    color: theme.colors.purple[90],
  },
})

const arrowStyles = {
  transform: "translateY(4px) rotate(-90deg)",
}

const SamePageLink = ({ id, children }) => {
  const handleClick = ev => {
    ev.preventDefault()
    const elem = document.querySelector(`#${id}`)

    if (elem) {
      const offset = elem.getBoundingClientRect().top + window.scrollY

      window.scroll({
        top: offset,
        left: 0,
        behavior: getAnimationsEnabled() ? `smooth` : `auto`,
      })
    }
  }

  return (
    <a css={theme => linkStyles(theme)} href={`#${id}`} onClick={handleClick}>
      {children} <DownRightArrow css={arrowStyles} />
    </a>
  )
}

export default SamePageLink
